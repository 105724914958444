import React, { useCallback, useEffect } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { useState } from 'react';
import { constants } from '../../../constants';
import CommonPagination from '../../../components/shared/CommonPagination';
import { Link, useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useGetReportListMutation, useUpdateReportedItemMutation } from '../../../rtk/services/reportApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { customSwalFire, intToBool, useModuleAccess } from '../../../helper';
import moment from 'moment';
import ExportButton from '../../../components/helper/exportButton';

function ReportedList(props) {
    const [itemList, setItemList] = useState([]);
    const location = useLocation();
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Reported_Users, constants.privilegeType.Read);
    const { hasEditPrivilege } = useModuleAccess(constants.moduleType.Reported_Users, constants.privilegeType.Edit);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [reportType, setReportType] = useState("");
    const [role, setRole] = useState("");
    const [searchKey, setSearchKey] = useState("");
    const [searchValue] = useDebounce(searchKey, 1000);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetReportListMutation();
    const [updateItem, { isSuccess: updateSuccess, isLoading: updateLoading, reset }] = useUpdateReportedItemMutation();
    const getItemList = useCallback(() => {
        getList({
            reported_status: reportType,
            search_keyword: searchValue,
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            role: role == 1 ? "hm" : role == "0" ? "js" : ""
        });
    }, [getList, reportType, pageLimit, pageOffset, searchValue, role]);
    useEffect(() => { getItemList(); }, [getItemList])

    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resSuccess]);
    useEffect(() => {
        if (updateSuccess) {
            reset();
            getItemList();
        }
    }, [updateSuccess])
    useEffect(() => {
        if (location.search?.includes("pageOffset")) {
            let params = new URLSearchParams(location.search);
            const search = params.get("search");
            const status = params.get("status");
            const pageOffset = params.get("pageOffset");
            setSearchKey(search);
            setReportType(status);
            setPageOffset(pageOffset);
        }
    }, [location.search])
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {(resLoading || updateLoading) && (<Loader />)}
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">reported users ({totalCount})</h5>
                            <div className="action_btns">
                                <ExportButton type={constants.exportKeys.reported_users} />
                            </div>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                 <div className="col-lg-4 col-md-6">
                                    <div className="form-group select-option">
                                        <select className="form-control form-select" value={role} onChange={(e) => { setRole(e.target.value); setPageOffset(0) }}>
                                            {constants.userTypeReport?.map((item, i) => (
                                                <option key={i} value={item?.value}>{item?.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-group select-option">
                                        <select className="form-control form-select" value={reportType} onChange={(e) => { setReportType(e.target.value); setPageOffset(0) }}>
                                            {constants.statusTypeReport?.map((item, i) => (
                                                <option key={i} value={item?.value}>{item?.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-group">
                                        <input type="text" value={searchKey} className='form-control' placeholder='Search by name, created by' onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body py-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th><sr className="no">{"sr.no."}</sr>
                                                {/* <div className="sort ms-2">
                                                    <button type="button" className='button'> <i className='bi-sort-numeric-down' /> </button>
                                                    <button type="button" className='button'> <i className='bi-sort-numeric-up-alt' /> </button>
                                                </div> */}
                                            </th>
                                            <th>reported user
                                                {/* <div className="sort ms-2">
                                                    <button type="button" className='button'> <i className='bi-sort-alpha-down' /> </button>
                                                    <button type="button" className='button'> <i className='bi-sort-alpha-up-alt' /> </button>
                                                </div> */}
                                            </th>
                                            <th>user type
                                                {/* <div className="sort ms-2">
                                                <button type="button" className='button'> <i className='bi-sort-down' /> </button>
                                                    <button type="button" className='button'> <i className='bi-sort-up-alt' /> </button>
                                                </div> */}
                                            </th>
                                            <th>report count</th>
                                            <th>status</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemList?.length > 0 ? itemList?.map((item, i) => (
                                                <tr key={i}>
                                                    <td data-label="sr.no.">{i+1}</td>
                                                    <td data-label="reported user">
                                                        <Link to={`/user/detail/${item?.uuid}?search=${searchKey}&status=${reportType}&pageOffset=${pageOffset}&type=reportUserDetail`} className='link-color'>{item?.user_name || "-"}</Link>
                                                    </td>
                                                    <td data-label="user type">{item?.role === "HM" ? "hiring manager" : "job seeker"|| "-"}</td>
                                                    <td data-label="report count">
                                                        <Link to={`/reported/detail/${item?.user_id}?role=${item?.role === "HM" ? "hm" : role == "JS" ? "js" : ""}`} className='link-color'>{item?.reported_count || "-"}</Link>
                                                    </td>
                                                    <td data-label="status">{ intToBool(item?.is_active) ? (intToBool(item?.is_resolved) ? "resolved" : "open") : "blocked" }</td>
                                                    {hasEditPrivilege && (
                                                         <td data-label="action">
                                                        <div className="action_wrap align-items-center">
                                                                <Link 
                                                                    to={`/reported/detail/${item?.user_id}?role=${item?.role === "HM" ? "hm" : role == "JS" ? "js" : ""}`}
                                                                    className={`btn btn-icon ${!intToBool(item?.is_resolved) && "bg-danger border-danger"}`}
                                                                >
                                                                    <i className="bi-eye" />
                                                                </Link>
                                                        </div>
                                                        </td>
                                                    )}
                                                 </tr>
                                            ))
                                            :
                                                <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                        </div>
                    </div>
                </AdminLayout>
            )}
        </>

    );
}

export default ReportedList;