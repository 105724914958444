import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../utils/baseQuery";

export const reportApi = createApi({
    reducerPath: "reportApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getReportList: builder.mutation({
            query: (body) => {
                const url = `page_limit=${body?.page_limit || 10}&page_offset=${body?.page_offset || 1}&reported_status=${body?.reported_status || ""}&reported_id=${body?.reported_id || ""}&search_keyword=${body?.search_keyword || ""}&role=${body?.role || ""}&user_id=${body?.user_id || ""}`;
                return {
                    url: `/admin/reported-user?${url}`
                }
            },
        }),
        updateReportedItem: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/reported-user/`,
                    method: "put",
                    body
                }
            },
        }),
    }),
});

export const {
    useGetReportListMutation,
    useUpdateReportedItemMutation
} = reportApi;
