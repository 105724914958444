import React, { useCallback, useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetReportListMutation, useUpdateReportedItemMutation } from '../../../rtk/services/reportApi';
import Loader from '../../../components/loader';
import { customSwalFire, intToBool, useModuleAccess } from '../../../helper';
import { constants } from '../../../constants';
import moment from 'moment';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';

function ReportedDetails(props) {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const role = queryParams.get('role');
    const navigate = useNavigate();
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Reported_Users, constants.privilegeType.Read);
    const { hasEditPrivilege } = useModuleAccess(constants.moduleType.Reported_Users, constants.privilegeType.Edit);
    const [item, setItem] = useState(null);
    const [itemList, setItemList] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetReportListMutation();
    const [updateItem, { isSuccess: updateSuccess, isLoading: updateLoading, reset }] = useUpdateReportedItemMutation();

    const getItemList = useCallback(() => {
        getList({
            user_id: id,
            role: role,
        });
    }, [id, getList]);

    const handleBlockActions = async(event, item, type) => {
        let title = type == constants.reportEnum.block ? "block user" : "unblock user";
        let textMessage = type == constants.reportEnum.block ? "are you sure you want to block user?" : "are you sure you want to unblock user?";
        customSwalFire(title, textMessage, "question").then((result) => {
            if (result.isConfirmed) {
                (event.target.checked) ? event.target.checked = true : event.target.checked = false;
                if (result.value) {
                    updateItem({
                        reported_id: item?.id,
                        report_type: type
                    })
                }
            } else {
                (event.target.checked) ? event.target.checked = false : event.target.checked = true;
            }
        });
    }
    const handleDismissActions = async(event, item, type) => {
        console.log(item);
        updateItem({
            reported_id: item?.id,
            report_type: type
        })
    }
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (updateSuccess) {
            reset();
            getItemList();
        }
    }, [updateSuccess])
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data?.[0]);
            setItemList(resData?.data);
        }
    }, [resData, resSuccess]);
    const goBack = () => {
        if (location.search?.includes("reportDetail")) {
            navigate(`/reported${location?.search}`);
        } else {
            navigate(-1);
        }
    }
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {(resLoading || updateLoading) && (<Loader />)}
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">report details</h5>
                            <div className="action_btns">
                                {hasReadPrivilege && (
                                    <button type="button" className="btn btn-primary" onClick={(e) => { goBack() }}>
                                        <i className="bi-arrow-left" /> Back
                                    </button>
                                )}                                {hasEditPrivilege && (
                                    <>
                                        <button type="button" disabled={intToBool(item?.is_resolved)} className="btn btn-primary" onClick={(e) => { handleDismissActions(e, item, constants.reportEnum.resolved) }}> <i className="fas fa-lock" /> dismiss report</button>
                                        {/* {!intToBool(item?.is_resolved) && (<button type="button" className="btn btn-primary" onClick={(e) => { handleBlockActions(e,item, constants.reportEnum.resolved) }}> <i className="bi bi-person-check-fill" /> mark as resolved</button>)} */}
                                        {item?.reported_by == 1 ? intToBool(item?.reported_info?.js_reported_user_is_active) ? (<button type="button" className="btn btn-primary" onClick={(e) => { handleBlockActions(e,item, constants.reportEnum.block) }}> <i className="fas fa-lock" /> block reported user</button>) : (<button type="button" className="btn btn-primary" onClick={(e) => { handleBlockActions(e,item, constants.reportEnum.unblock) }}> <i className="fas fa-unlock" /> un block reported user</button>) : ""}
                                        {item?.reported_by == 0 ? intToBool(item?.reported_info?.hm_reported_user_is_active) ? (<button type="button" className="btn btn-primary" onClick={(e) => { handleBlockActions(e,item, constants.reportEnum.block) }}> <i className="fas fa-lock" /> block reported user</button>) : (<button type="button" className="btn btn-primary" onClick={(e) => { handleBlockActions(e,item, constants.reportEnum.unblock) }}> <i className="fas fa-unlock" /> un block reported user</button>) : ""}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='reported_user_value'>
                            <div className='reported_user_text'>
                                <h6 className="card-title">reported user</h6>
                                <span>{item?.role === 'hm' ? item?.reported_info?.hm_reported_user_name : item?.reported_info?.js_reported_user_name || "-"}</span>
                                <div className='dot'></div>
                                <span>{item?.role === 'hm' ? 'hiring manager' : item?.role === 'js' ? 'job seeker': "-" || "-"}</span>
                            </div>
                            <div className='reported_user_text'>
                                <h6 className="card-title">email address</h6>
                                <span>{item?.role === 'hm' ? item?.reported_info?.hm_email : item?.role === 'js' ? item?.reported_info?.js_email : "-"}</span>
                            </div>
                        </div>

                        <div className='report_table'>
                            <span className='reported-text-title'>reporting user information</span>
                            <table className="table table-striped no_wrap mt-5">
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>name</th>
                                            <th>email</th>
                                            <th>reason</th>
                                            <th>timestamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemList?.length > 0 ?
                                                itemList?.map((item, i) => (
                                                    <tr>
                                                        <td data-label="user_name">{item?.reported_info?.hm_reporter_username || "-"}</td>
                                                        <td data-label="email">{item?.reported_info?.js_email || "-"}</td>
                                                        <td data-label="reason">{item?.message || "-"}</td>
                                                        <td data-label="timestamp">{moment.utc(item?.created_at).local().format("MMM DD, YYYY HH:mm:ss") || "-"}</td>
                                                    </tr>
                                                ))
                                                :
                                                <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </table>
                        </div>
                    </div>
                </AdminLayout>
            )}
        </>

    );
}

export default ReportedDetails;